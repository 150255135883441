:root {
    --kh-color-blue: #234B71;
    --kh-color-orange: #df6629;
}

.bg-kh-blue {
    background: var(--kh-color-blue);
    background-color: var(--kh-color-blue);
}

.bg-kh-orange {
    background: var(--kh-color-orange);
    background-color: var(--kh-color-orange);
}

#app {
    height: 100%;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 1000px;
    background-color: white;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    margin: 0 auto;
    border-radius: 12px;
}

#login-form {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 60px;
}

#login-form p {
    margin-bottom: 30px;
}

#login-form p.form-title {
    color: #333333;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
}

#login-form .ant-form-item-label>label.ant-form-item-required::before {
    display: none;
}

.ant-form-item-control-input-content {
    text-align: left;
}

#login-form .ant-input-affix-wrapper {
    padding: 12px 15px;
}

#login-form_username {
    height: 48px;
}

#login-form .ant-btn {
    height: 42px;
    letter-spacing: 1px;
    border-radius: 6px;
}

.login-form-button {
    width: 100%;
}

.illustration-wrapper {
    display: flex;
    align-items: flex-end;
    max-width: 800px;
    min-height: 100%;
    background-color: #fffdf2;
}

.illustration-wrapper img {
    display: block;
    width: 100%;
}

@media screen and (max-width: 1023px) {
    .login-box {
        flex-direction: column;
        box-shadow: none;
    }

    .illustration-wrapper {
        max-width: 100%;
        min-height: auto;
    }

    #login-form {
        max-width: 100%;
    }
}

// .ant-menu-dark .ant-menu-item,
// .ant-menu-dark .ant-menu-item-group-title,
// .ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
    color: #FFFFFF;
}

.ant-menu-dark .ant-menu-item>span>a:hover {
    color: var(--kh-color-orange);
}

.ant-menu-dark .ant-menu-item-active>span>a {
    color: var(--kh-color-orange);
}

.gx-card .ant-card-head-title {
    padding: 25px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}


// 1	Belum Bayar
// 2	Terbayar
// 3	Failed
// 4	Ditolak
// 5	Fraud
// 6	Form Belum Diisi
.row-order-status-draft {
    color: #000000;
}

.row-order-status-incomplete-data {
    color: #350057;
}

.row-order-status-unpaid {
    color: #E85607;
}

.row-order-status-paid {
    color: #15ff00;
}

.row-order-status-failed {
    color: #770000;
}

.row-order-status-rejected {
    color: #686969;
}

.row-order-status-fraud {
    color: #524af2;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.gx-main-content-wrapper {
    padding-bottom: 32px;
}

@import "sidebar.less";